import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    body1: {
      fontSize: '0.95rem'
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#009432',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ff5252'
    }
  }
})

export default theme
